<template>
    <div class="location">
        <span class="homePage" @click="homePageUrl">首页</span>
        <template v-if="locationArr != null">
            <span v-for="(item,index) in locationArr" :key="index">
                <span style="margin-right: 5px;margin-left:5px;">></span>
                <span @click="homePageUrlTwo(item.url)" class="homePage">{{item.title}}</span>
            </span>
			<span @click="$router.back(-1)" class="homePage"> > 返回</span>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        props: {
            locationArr: {type: Array, default: null}
        },
        mounted() {

        },
        methods: {
            homePageUrl() {
                location.href = "/index";
            },
            homePageUrlTwo(url) {
                if (url != '') {
                    location.href = url;
                }
            }
        }
    }
</script>
<style type="text/css">
    .location {
        width: 1200px;
        height: 100px;
        margin: 0 auto;
        font-size: 18px;
        line-height: 100px;
        color: #000000;
        text-align: left;
        /* border-bottom: 1px solid #f2f2f2; */
    }

    .homePage {
        cursor: pointer;
    }
</style>
